$(function () {
    initNav();
    initCarousel();
    initBrands();
    initEqualHeights();
    initTabs();
    initReviews();
    initWithSubnav();
    initForm();
    initFaq();
    initMatchheight();
    initCookiebox();
    initIframes();
    initReadMore();

    if (settings.register) {
        initRegisterSearch();
    }
});

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        initNav();
        initEqualHeights();
    }, 100));
});

$.ajaxSetup({
    headers: {
        [csrf.header]: csrf.token
    }
});

function initRegisterSearch() {
    $('#zoekterm').focus();
    var $form = $('#zoekterm').parents('form');

    $form.on('submit', function (e) {
        var zoekterm = $('#zoekterm').val();

        if (zoekterm.match(/^[0-9]+$/)) {
            e.preventDefault();

            $.ajax({
                type: 'GET',
                url: $form.attr('action'),
                data: {
                    zoekterm: zoekterm
                },
                dataType: 'json',
                success: function (cart) {
                    updateCart(cart);

                    if (window.location.pathname !== '/winkelmandje') {
                        window.location.href = window.location.origin + '/winkelmandje';
                    }
                }
            });

            $('#zoekterm').val('').focus();
        }
    });
}

function initNav() {
    $(window).resize(function () {
        $('header .navigation .menu .menu-btn, header .navigation nav ul > li, header .navigation .menu .search-btn, header .navigation .menu .cart-btn').unbind('click');
    });

    // Navigation
    if ($(window).width() < 992) {

        // Menu
        $('header .navigation .menu .menu-btn').on('click', function () {
            $(this).toggleClass('active');

            if ($(this).hasClass('active')) {
                $('header .navigation nav').slideDown({
                    duration: 300
                }).css({
                    zIndex: 1
                });

                closeSearch($('header .navigation .menu .search-btn'));
                closeCart($('header .navigation .menu .cart-btn'));
            } else {
                closeNav($(this));
            }
        });
        $('header .navigation nav ul > li').each(function () {
            $(this).find('a:first-child').on('click', function (e) {
                if($(this).parents('li').find('.sub-nav').length){
                    e.preventDefault();
                    $(this).parents('li').toggleClass('open');

                    if ($(this).parents('li').hasClass('open')) {
                        $('header .navigation nav').addClass('sub-open');

                        $(this).parents('li').css({
                            height: $(this).outerHeight() + $(this).siblings('.sub-nav').outerHeight()
                        });

                        $(this).parents('li').find('ul li').each(function () {
                            $(this).find('a').click(function () {
                                window.location = $(this).attr('href');
                            });
                        });
                    } else {
                        $('header .navigation nav').removeClass('sub-open');

                        $(this).parents('li').css('height', '');
                    }
                }
            });
        });

        // Search
        $('header .navigation .menu .search-btn').on('click', function () {
            $(this).toggleClass('active');

            if ($(this).hasClass('active')) {
                $('header .navigation .search').slideDown({
                    duration: 300
                }).css({
                    zIndex: 1
                });

                closeNav($('header .navigation .menu .menu-btn'));
                closeCart($('header .navigation .menu .cart-btn'));
            } else {
                closeSearch($(this));
            }
        });

        // Cart
        $('header .navigation .menu .cart-btn').on('click', function () {
            $(this).toggleClass('active');

            if ($(this).hasClass('active')) {
                $('header .navigation .cart').slideDown({
                    duration: 300
                }).css({
                    zIndex: 1
                });

                closeNav($('header .navigation .menu .menu-btn'));
                closeSearch($('header .navigation .menu .search-btn'));
            } else {
                closeCart($(this));
            }
        });
    } else {
        var sub,
            li;

        if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {
            $('header .navigation nav > ul > li > a').each(function () {
                var clicked = 0;

                $(this).click(function (e) {
                    if (clicked === 0) {
                        e.preventDefault();

                        clicked = 1;
                    } else if (clicked === 1) {
                        // Do nothing
                    }
                });
            });
        }

        $('header .navigation nav > ul > li').each(function () {
            if ($(this).find('a:hover').length) {
                sub = $(this).find('.sub-nav');
                li = $(this);

                // $('header .navigation nav ul li .sub-nav').each(function () {
                //     $(this).css({
                //         top: '100%'
                //     });
                // });

                if (sub.outerHeight() > 0) {
                    var subheight = sub.outerHeight();
                    if (subheight < 382.5) {
                        subheight = 382.5;
                    }

                    // $('header .navigation nav .sub-nav-background').css({
                    //     top: '100%',
                    //     height: subheight,
                    //     opacity: 1
                    // });
                }
                li.find('a').addClass('active');
            }
        });

        $('header .navigation nav > ul > li, header .navigation nav > ul > li > .sub-nav, header .navigation nav > ul > li > a').mouseover(function () {

            if ($(this).is('li')) {
                sub = $(this).find('.sub-nav');
                li = $(this);
            } else if ($(this).is('a')) {
                sub = $(this).next('.sub-nav');
                li = $(this).parent();
            } else {
                sub = $(this);
                li = $(this).parent();
            }

            // $('header .navigation nav ul li .sub-nav').each(function () {
            //     $(this).css({
            //         top: '100%'
            //     });
            // });

            if (sub.outerHeight() > 0) {
                var subheight = sub.outerHeight();
                if (subheight < 382.5) {
                    subheight = 382.5;
                }

                // $('header .navigation nav .sub-nav-background').css({
                //     top: '100%',
                //     height: subheight,
                //     opacity: 1
                // });
            }
            li.find('a').addClass('active');
        });
        $('header .navigation nav > ul > li, header .navigation nav > ul > li > .sub-nav, header .navigation nav > ul > li > a').mouseleave(function () {

            // $('header .navigation nav ul li .sub-nav').each(function () {
            //     $(this).css({
            //         // top: 'calc(100% + 15px)'
            //         top: '100%'
            //     });
            // });
            // $('header .navigation nav .sub-nav-background').css({
            //     // top: 'calc(100% + 15px)',
            //     top: '100%',
            //     height: 0,
            //     opacity: 0
            // });
            li.find('a').removeClass('active');
        });

        $('header .navigation nav ul li').each(function () {
            var img = $(this).find('.image img'),
                original = img.attr('src');

            $(this).find('.sub-nav .navigation ul li').each(function () {
                $(this).find('a').hover(function () {
                    if ($(this).data('image') != '') {
                        img.attr('src', $(this).data('image'));
                    } else {
                        img.attr('src', original);
                    }
                }, function () {
                    img.attr('src', original);
                });
            });
        });
    }
}

function closeNav(e) {
    e.removeClass('active');

    $('header .navigation nav').slideUp({
        duration: 300
    }).css({
        zIndex: 0
    });

    $('header .navigation nav').removeClass('sub-open');
    $('header .navigation nav ul li.open').removeClass('open').css('height', '');
}

function closeSearch(e) {
    e.removeClass('active');

    $('header .navigation .search').slideUp({
        duration: 300
    }).css({
        zIndex: 0
    });
}

function closeCart(e) {
    e.removeClass('active');

    $('header .navigation .cart').slideUp({
        duration: 300
    }).css({
        zIndex: 0
    });
}

function initCarousel() {
    $('#carousel').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        arrows: false
    });
}

function initReviews() {
    $('.reviewSlider').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        arrows: false,
        dots:true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
}

function initReadMore() {
    $('.read-more').on('click', function() {
        if ($(this).hasClass('open')) {
            $(this).removeClass('open');
        } else {
            $(this).addClass('open');
        }
    });
}

function initBrands() {
    $('footer .brands').slick({
        mobileFirst: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        dots: true,

        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                    dots: true
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 8,
                    dots: false
                }
            }
        ]
    });
}

function initEqualHeights() {
    if ($('.equalHeights').length) {
        $('.equalHeights').each(function () {
            var parent = $(this),
                items = parent.data('equals').replace(/ /g, '').split(','),
                height;

            items.forEach(function (item) {
                height = 0;

                parent.find(item).each(function () {
                    if ($(this).height() > height) {
                        height = $(this).height();
                    }
                });

                if (parent.hasClass('no-mobile') && $(window).width() < 767) {
                    // Do nothing...
                } else {
                    parent.find(item).css({
                        minHeight: height
                    });
                }
            });
        });
    }
}

function initTabs() {
    if ($('.tabs').length > 0) {
        $('.tabs nav ul .tab').each(function () {
            $(this).click(function () {
                $('.tabs nav ul .tab.active').removeClass('active');
                $(this).addClass('active');

                if ($(this).hasClass('active')) {
                    $('.tabs .tab-contents .tab-content.active').removeClass('active');
                    $('#' + $(this).data('tab')).addClass('active');
                }
            });
        });
    }
}

function initWithSubnav() {
    if ($('.with-subnav').length && $(window).width() < 768) {
        $('.with-subnav').click(function () {
            $(this).toggleClass('active');

            if ($(this).hasClass('active')) {
                $(this).find('.holder').css({
                    height: $(this).find('.holder > ul').outerHeight() + 30
                });
            } else {
                $(this).find('.holder').css({
                    height: 0
                });
            }
        });
    } else {
        $('.with-subnav').hover(function () {
            $(this).addClass('active');
            $(this).find('.holder').css({
                height: $(this).find('.holder > ul').outerHeight() + 30
            });
        }, function () {
            $(this).removeClass('active');
            $(this).find('.holder').css({
                height: 0
            });
        });
    }
}

function initForm() {
    if ($('form.form').length) {
        $('form.form .form-group').each(function () {
            if ($(this).find('input, textarea').val() != '') {
                $(this).addClass('active');
            }

            $('input, textarea').focus(function () {
                $(this).parents('.form-group').addClass('active');
            }).focusout(function () {
                if ($(this).val() == '') {
                    $(this).parents('.form-group').removeClass('active');
                }
            });

            if ($(this).find('input, textarea').hasClass('required')) {
                $(this).find('label').html($(this).find('label').text() + ' <span>*</span>');
            }
        });
    }
}

function initFaq() {
    if ($('#faq').length > 0) {
        $('.question').on('click', function () {
            $(this).siblings().removeClass('show');
            $(this).toggleClass('show');
        });
    }
}

function initMatchheight() {
    $('.matchheight').matchHeight();
}

function initIframes() {
    $('#omschrijving iframe:not(.no-wrapper), .page-content iframe:not(.no-wrapper)').each(function (key, iframe) {
        $(iframe).wrap('<div class="iframe-wrapper"></div>');
    });
}

function initCookiebox() {
    if ($('#cookiebox').length && document.cookie.indexOf('cookiebox=') === -1) {
        setTimeout(function () {
            $('#cookiebox').hide().fadeIn('fast').css({
                bottom: 30
            });
        }, 500);

        $('#cookiebox .accept').click(function () {
            setCookie('cookiebox', 1, 365);
            $('#cookiebox').fadeOut().css({
                bottom: -230
            });
        });
    }
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}

function removeCookie(name) {
    if (get_cookie(name)) {
        document.cookie = name + '=; Max-Age=-99999999;';
    }
}