/**
 * Update order.
 *
 * @param order
 */


function updateOrder() {
    const $order = $('#order');
    const $form = $('#checkoutForm');
    const $passwordWrapper = $('#passwordWrapper');
    const $shippingMethod = $form.find('input[name="shipping_method"]:checked');
    const $paymentMethod = $form.find('input[name="payment_method"]:checked');
    const $createAccount = $form.find('input[name="create_account"]');
    const $remboursPaymentMethod = $('input[name="payment_method"][value="rembours"]');

    const data = {};
    switch ($shippingMethod.val()) {
        case 'billing_address':
            if($('#billingAddressOptionExisting').is(':checked')) {
                data.country = $('select[name=existing_billing_address] option:selected').data('country');
            } else {
                data.country = $form.find('#billing_country').val();
            }
            break;
        case 'shipping_address':
            data.country = $form.find('#shipping_country').val();
            break;
    }
    data.shipping_method = $shippingMethod.val();
    data.payment_method = $paymentMethod.val();

    if (data.country === 'NL') {
        $remboursPaymentMethod.prop('disabled', false);
        $remboursPaymentMethod.closest('div').show();
    } else {
        if ($remboursPaymentMethod.is(':checked')) {
            $remboursPaymentMethod.prop('checked', false);
            $('input[name="payment_method"]:first').prop('checked', true);
        }
        $remboursPaymentMethod.prop('disabled', true);
        $remboursPaymentMethod.closest('div').hide();
    }

    if ($createAccount.prop('checked') === true) {
        $passwordWrapper.find('input').attr('disabled', false);
        $passwordWrapper.show();
    } else {
        $passwordWrapper.hide();
        $passwordWrapper.find('input').attr('disabled', true);
    }

    $.ajax({
        type: 'GET',
        data,
        url: url.order.data,
        dataType: 'json',
        success: function (order) {

            if ($order.length) {
                if (order.rows.length) {
                    const containerTemplate = $('script[data-template="order-container"]').text();

                    const $tax = $('<div/>', {class: 'row tax'});
                    order.tax_data.forEach(function (entry) {
                        $tax.append($('<div/>', {class: 'col-xs-8'}).text('BTW (' + entry.rate + '%)'))
                            .append($('<div/>', {class: 'col-xs-4 text-right'}).text(entry.calculated));
                    });

                    const $discount = $('<div/>', {class: 'row discount'});
                    if (null !== order.discount_coupon) {
                        $discount.append($('<div/>', {class: 'col-xs-8'}).text('Korting: ' + order.discount_coupon.code.toUpperCase()))
                            .append($('<div/>', {class: 'col-xs-4 text-right'}).text('- ' + order.discount));
                    }

                    if (null !== order.discount && null === order.discount_coupon) {
                        $discount.append($('<div/>', {class: 'col-xs-8'}).text('Korting: '))
                            .append($('<div/>', {class: 'col-xs-4 text-right'}).text('- ' + order.discount));
                    }

                    const $container = $(containerTemplate.replace(/{{ id }}/g, order.id)
                        .replace(/{{ count }}/g, order.count)
                        .replace(/{{ subtotal }}/g, order.total_without_discount)
                        .replace(/{{ discount }}/g, $discount[0].outerHTML)
                        .replace(/{{ tax }}/g, $tax[0].outerHTML)
                        .replace(/{{ shipping_fee }}/g, order.shipping_fee)
                        .replace(/{{ total }}/g, order.total_plus_shipping));

                    $order.empty()
                        .append($container);

                    const $orderRows = $('.order-rows');
                    $orderRows.empty();
                    const rowTemplate = $('script[data-template="order-row"]').text();
                    $.each(order.rows, function (index, row) {
                        let options = '';
                        let fields = '';
                        if (row.variant.options.length) {
                            const optionFieldTemplate = '<input type="hidden" name="options[{{ option }}]" value="{{ value }}">';
                            options += '<dl>';
                            $.each(row.variant.options, function (index, option) {
                                options += '<dt>' + option.name + '</dt><dd style="padding-left: 15px;">' + option.value.name + '</dd>';
                                fields += optionFieldTemplate.replace(/{{ option }}/g, option.id)
                                    .replace(/{{ value }}/g, option.value.id);
                            });
                            options += '</dl>';
                        }

                        const $row = $(rowTemplate.replace(/{{ id }}/g, row.id)
                            .replace(/{{ variant.product.image }}/g, (row.variant.product.image ? row.variant.product.image : '/img/no-image.jpg'))
                            .replace(/{{ variant.product.name }}/g, row.variant.product.name)
                            .replace(/{{ variant.options }}/g, options)
                            .replace(/{{ variant.price }}/g, row.variant.price)
                            .replace(/{{ quantity }}/g, row.quantity)
                            .replace(/{{ fields }}/g, fields)
                            .replace(/{{ subtotal }}/g, row.subtotal)
                            .replace(/{{ url.product.show }}/g, url.product.show.replace(/@slug/g, row.variant.product.slug).replace(/@id/g, row.variant.product.id)));

                        $orderRows.append($row);
                    });

                    $orderRows.trigger('order.loaded');
                }
            }
        }
    });
}

// Uitlezen en bepalen van adresgegevens
function checkPostalZip(type) {
    const country = $('select[name="' + type + '_country"]').val();
    const postalCode = $('input[name="' + type + '_postal_code"]').val();
    const houseNumber = $('input[name="' + type + '_house_number"]').val();
    if (country === 'NL') {
        if (postalCode !== '' && houseNumber !== '') {
            delayGetAdress(function () {
                getAdressByPostalZip(postalCode, houseNumber, type);
            }, 400);
        }
    }
};

// Aanroepen van de postcode api
function getAdressByPostalZip(postalCode, houseNumber, type) {
    postalCode = cleanUpZipcode(postalCode);
    $.ajax({
        url: '/postcode/address/' + postalCode + '/' + houseNumber,
        type: 'GET',
        async: true,
        success: function (result) {
            if (typeof (result.exception) === 'undefined') {
                handleAdressRequestData(result, type);
            }
        }
    });
};

// Timeout
var delayGetAdress = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

function handleAdressRequestData(adress, type) {
    $('input[name="' + type + '_street"]').val(adress.street);
    $('input[name="' + type + '_city"]').val(adress.city);

    $('input[name="' + type + '_street"], input[name="' + type + '_city"]').closest('.form-group').addClass('active');
};

// Opschonen van de postcode
function cleanUpZipcode(zipcode) {
    var newZipcode = zipcode.trim();
    newZipcode = newZipcode.replace(/ /g, '');
    newZipcode = newZipcode.toUpperCase();
    return newZipcode;
};

function updateBillingAddress() {
    const $existingBillingAddress = $('#existingBillingAddress');
    const $newBillingAddress = $('#newBillingAddress');
    const checkedBillingAddressOption = $('input[name="billing_address_option"]:checked');
    const value = checkedBillingAddressOption.val();

    console.log(value);

    switch (value) {
        case 'existing':
            hideAndDisable($newBillingAddress);
            showAndEnable($existingBillingAddress);
            break;
        case 'new':
            hideAndDisable($existingBillingAddress);
            showAndEnable($newBillingAddress);
            break;
    }
}

function showAndEnable($element) {
    $element.show();
    $element.find('radio, input, select').prop('disabled', false);
}

function hideAndDisable($element) {
    $element.hide();
    $element.find('radio, input, select').prop('disabled', true);
}

$(document).ready(function () {
    if ($('#order').length) {
        $('input[name="payment_method"]:first').prop('checked', true);

        $('input[name="billing_address_option"]').on('change', function() {
            updateBillingAddress();
        });

        $('#billing_country, #shipping_country, input[name="shipping_method"], input[name="payment_method"], input[name="create_account"], input[name="billing_address_option"]').on('change', function () {
            updateOrder();
        });

        $('input[name="payment_method"]').on('change', function () {
            var shippingMethodPickup = $('#shippingMethodPickup');
            var shippingMethodBillingAddress = $('#shippingMethodBillingAddress');
            var row = shippingMethodPickup.closest('.row');

            if ($('input[name="payment_method"]:checked').val() == 'klarna') {
                if (shippingMethodPickup.is(':checked')) {
                    shippingMethodBillingAddress.click();
                }
                shippingMethodPickup.attr('disabled', true);
                row.fadeOut();
            } else {
                shippingMethodPickup.attr('disabled', false);
                row.fadeIn();
            }
        });

        // Factuur / standaard afleveradres
        checkPostalZip('billing');
        if ($('select[name="billing_country"]').length > 0
            && $('input[name="billing_postal_code"]').length > 0
            && $('input[name="billing_house_number"]').length > 0) {
            $('select[name="billing_country"]').change(function () {
                checkPostalZip('billing');
            });
            $('input[name="billing_postal_code"], input[name="billing_house_number"]').on('keyup, blur', function () {
                checkPostalZip('billing');
            });
        }

        // Afleveadres
        checkPostalZip('shipping');
        if ($('select[name="shipping_country"]').length > 0
            && $('input[name="shipping_postal_code"]').length > 0
            && $('input[name="shipping_house_number"]').length > 0) {
            $('select[name="shipping_country"]').change(function () {
                checkPostalZip('shipping');
            });
            $('input[name="shipping_postal_code"], input[name="shipping_house_number"]').on('keyup, blur', function () {
                checkPostalZip('shipping');
            });
        }

        updateOrder();
        updateBillingAddress();

        const $checkout = $('#checkout');
        const $shippingAddress = $('#shippingAddress');
        const $servicePoint = $('#servicePoint');

        $checkout.on('update', function () {
            const $method = $('input[name="shipping_method"]:checked');
            switch ($method.val()) {
                case 'shipping_address':
                    $shippingAddress.find('input, select').each(function () {
                        $(this).prop('disabled', false);
                    });
                    $servicePoint.find('input, select').each(function () {
                        $(this).prop('disabled', true);
                    });
                    $servicePoint.hide();
                    $shippingAddress.show();
                    break;
                case 'service_point':
                    $servicePoint.find('input, select').each(function () {
                        $(this).prop('disabled', false);
                    });
                    $shippingAddress.find('input, select').each(function () {
                        $(this).prop('disabled', true);
                    });
                    $shippingAddress.hide();
                    $servicePoint.show();
                    break;
                case 'billing_address':
                case 'pickup':
                    $shippingAddress.find('input, select').each(function () {
                        $(this).prop('disabled', true);
                    });
                    $servicePoint.find('input, select').each(function () {
                        $(this).prop('disabled', true);
                    });
                    $servicePoint.hide();
                    $shippingAddress.hide();
                    break;
            }
        });

        $('input[name="shipping_method"]').on('change', function () {
            $checkout.trigger('update');
        });

        $checkout.trigger('update');
    }

    if ($('#order-login').length) {
        const form = $('#check-email form');
        const loader = $('#check-email .loader');
        const checkEmail = $('#check-email');
        const input = $('#check-email-input');
        const error = $('#check-email .help-blok');
        const login = $('#login-form');

        $('#edit-email').on('click', function (e){
            e.preventDefault();
            input.val('');
            form.show();
            login.hide();
        });

        $('#check-email-submit').on('click', function (e) {
            e.preventDefault();
            error.hide();

            if(input.val() == "" || !isEmail(input.val())){
                error.show();
                return false;
            }

            form.hide();
            loader.show();

            const redirect = $(this).data('redirect');
            const url = $(this).data('url');

            let data = {
                email: input.val()
            }

            $.ajax({
                type: 'GET',
                data,
                url: url,
                dataType: 'json',
                success: function (response) {
                    if (response.hasAccount) {
                        loader.hide();
                        login.show();
                        login.find('#email').val(data.email);
                    } else {
                        window.location.href = redirect;
                    }
                }
            });
        })
    }

    function isEmail(email) {
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    }
});