$(function () {
    initProductOverview();
    initDetail();
});

// Polyfills
if (!NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (fn, scope) {
        for (var i = 0, len = this.length; i < len; ++i) {
            fn.call(scope, this[i], i, this);
        }
    }
}

if (!Array.prototype.forEach) {
    Array.prototype.forEach = function (fn, scope) {
        for (var i = 0, len = this.length; i < len; ++i) {
            fn.call(scope, this[i], i, this);
        }
    }
}

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
        value: function (searchElement, fromIndex) {

            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If len is 0, return false.
            if (len === 0) {
                return false;
            }

            // 4. Let n be ? ToInteger(fromIndex).
            //    (If fromIndex is undefined, this step produces the value 0.)
            var n = fromIndex | 0;

            // 5. If n ≥ 0, then
            //  a. Let k be n.
            // 6. Else n < 0,
            //  a. Let k be len + n.
            //  b. If k < 0, let k be 0.
            var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

            // 7. Repeat, while k < len
            while (k < len) {
                // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                // b. If SameValueZero(searchElement, elementK) is true, return true.
                // c. Increase k by 1.
                // NOTE: === provides the correct "SameValueZero" comparison needed here.
                if (o[k] === searchElement) {
                    return true;
                }
                k++;
            }

            // 8. Return false
            return false;
        }
    });
}

function initProductOverview() {
    if ($('#products').length > 0) {
        // var $button = $('#products .page-content .read-more');
        // if ($('#products .page-content .text .text-wrapper p').length > 1) {
        //
        //     $('#products .page-content .text').css({
        //         height: $('#products .page-content .text .text-wrapper').children('p').first().outerHeight()
        //     });
        //
        //     $button.on('click', function () {
        //         $('#products .page-content .text').toggleClass('active');
        //
        //         var height = $('#products .page-content .text .text-wrapper').outerHeight();
        //
        //         if ($('#products .page-content .text').hasClass('active')) {
        //             $button.find('i').removeClass('icon-plus').addClass('icon-minus');
        //             $button.find('span').text('Lees minder');
        //
        //             $('#products .page-content .text').css({
        //                 height: height
        //             });
        //         } else {
        //             $button.find('i').removeClass('icon-minus').addClass('icon-plus');
        //             $button.find('span').text('Lees meer');
        //
        //             $('#products .page-content .text').css({
        //                 height: $('#products .page-content .text .text-wrapper').children('p').first().outerHeight()
        //             });
        //         }
        //     });
        // } else {
        //     $button.hide();
        // }

        $('.product').matchHeight();

        $('#perPage').on('change', function(e){
            e.preventDefault();
            window.location.href = $(this).val();
        });
    }
}

function initDetail() {
    if ($('#product').length > 0) {

        var screenWidth = $(window).width();
        if (screenWidth < 768) {
            var orderWrap = $('#orderWrap');
            var orderMobile = $('#orderMobile');
            var optionsDiv = $('#options');
            var mobileOptionsDiv = $('#mobileOptions');

            orderMobile.append(orderWrap);
            mobileOptionsDiv.append(optionsDiv);
        }

        initDetailGallery();
        initShoppingCart();
        initImageLightbox();
        initVariants();


        $('.aw-slider-popup').slick('setPosition');
    }
}

function initDetailGallery() {
    if ($('#product .gallery').length > 0) {
        $('#product .gallery .images').slick({
            slidesToShow: 1,
            fade: true,
            arrows: false,
            dots: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        dots: true,
                    }
                }
            ]
        });

        if ($('#product .gallery .images .slide').length < 2) {
            $('#product .gallery .nav').attr('style', 'display:none!important');
        } else {
            $('#product .gallery .nav').slick({
                mobileFirst: true,
                slidesToShow: 2,
                infinite: false,
                focusOnSelect: true,
                asNavFor: '#product .gallery .images',
                arrows: false,
                dots: true,
                prevArrow:'<button class="slick-prev slick-arrow" aria-label="Vorige" type="button" aria-disabled="false"><span class="icon-arrow-left"></span></button>',
                nextArrow:'<button class="slick-next slick-arrow" aria-label="Volgende" type="button" aria-disabled="false"><span class="icon-arrow-right"></span></button>',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 4,
                            dots: false,
                            arrows: true,
                        }
                    }
                ]
            });
        }
    }
}

function initShoppingCart() {
    $('.btn_buy').on('click', function () {
        $('h4.value_option').removeClass('danger')
        if (($('.values').length > 0 && $('.values .selected').length == $('.values').length) || $('.values').length == 0) {
            const $form = $('#addToCartForm');
            const data = $form.serialize();

            $.ajax({
                type: 'POST',
                url: $form.attr('action'),
                data,
                dataType: 'json',
                success: function (cart) {
                    gtag("event", "add_to_cart", {
                        currency: "EUR",
                        value: $('#productCartModal').data('product-price'),
                        items: [
                          {
                            item_id: $('#productCartModal').data('product-id'),
                            item_name: $('#productCartModal').data('product-name'),
                            affiliation: "Onlineskateshop.nl",
                            index: 0,
                            item_brand: $('#productCartModal').data('product-brand'),
                            item_category: $('#productCartModal').data('product-category'),
                            //location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
                            price: $('#productCartModal').data('product-price'),
                            quantity: 1
                          }
                        ]
                      });
                      
                    updateCart(cart);

                    // dataLayer.push({ ecommerce: null });
                    // dataLayer.push({
                    //     'event': 'addToCart',
                    //     'ecomm_prodid': $('#productCartModal').data('product-id'),
                    //     'ecomm_totalvalue': cart.total_plus_shipping,
                    //     'ecommerce': {
                    //         'currencyCode': 'EUR',
                    //         'add': {
                    //             'products': [{
                    //                 'name': $('#productCartModal').data('product-name'),         // Name or ID is required.
                    //                 'id': $('#productCartModal').data('product-id'),
                    //                 'price': $('#productCartModal').data('product-price'),
                    //                 'brand': $('#productCartModal').data('product-brand'),
                    //                 'category': $('#productCartModal').data('product-category'),
                    //                 'description': $('#productCartModal').data('product-description')
                    //             }]
                    //         }
                    //     }
                    // });
                }
            });

            if ($('.bundle-product', '#addToCartForm').length > 0) {
                $('.heading', '#productCartModal').text('De artikelen zijn toegevoegd aan je winkelmandje!');
            }

            $('#productCartModal').modal('show');
        } else {
            $('h4.value_option').addClass('text-danger');
        }
    });

    $('.add-bundle-to-cart').unbind().click(function (e) {
        e.preventDefault();

        var id = 0;
        var hidden = $(this).parent().parent().find('input[type="hidden"]');

        if (hidden.length > 0) {
            id = hidden.val();

            var selected = $('.bundle-product[value="' + id + '"]');

            if (selected.length > 0) {
                selected.remove();
                $(this).removeClass('bundle-selected');
            } else {
                $('#addToCartForm').append('<input type="hidden" class="bundle-product" name="bundle_products[]" value="' + id + '">');
                $(this).addClass('bundle-selected');
            }

            var l = 20;

            for (var i = 0; i <= 3; i++) {
                $(this).parent().parent().animate({
                    'margin-left': '+=' + (l = -l) + 'px',
                    'margin-right': '-=' + l + 'px'
                }, 100);
            }

        } else {
            var valsContainer = $(this).parent().parent().find('.bundle-variants');
            var cont = $(this).parent().parent().parent();

            valsContainer.find('li').each(function (v) {
                var id = $(this).attr('data-value');

                if ($('.bundle-product[value="' + id + '"]').length > 0) {
                    $('.bundle-product[value="' + id + '"]').remove();
                    $(this).addClass('selected');
                    $(this).find('input[type="radio"]').attr('checked', 'checked');
                } else {
                    $(this).removeClass('selected');
                    $(this).find('input[type="radio"]').removeAttr('checked');
                }
            });

            $('.inner', '#bundleVariantsModal').html(valsContainer.html());

            var button = $(this);

            $('li', '#bundleVariantsModal').unbind().click(function (e) {
                e.stopPropagation();
                e.preventDefault();

                var id = $(this).attr('data-value');
                var selected = $('.bundle-product[value="' + id + '"]');

                if (selected.length > 0) {
                    selected.remove();

                    button.removeClass('bundle-selected');
                } else {
                    $('#addToCartForm').append('<input type="hidden" class="bundle-product" name="bundle_products[]" value="' + id + '">');
                    button.addClass('bundle-selected');
                }

                $('#bundleVariantsModal').modal('hide');

                var l = 20;

                for (var i = 0; i <= 3; i++) {
                    cont.animate({
                        'margin-left': '+=' + (l = -l) + 'px',
                        'margin-right': '-=' + l + 'px'
                    }, 100);
                }
            });

            $('#bundleVariantsModal').modal('show');
        }
    });
}

function initImageLightbox() {
    $('.images').magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
            enabled: true
        }
    });
}

function initVariants() {
    const $variants = $(document).find('[data-variants]');
    const variants = JSON.parse($variants.text());

    updateOptions(variants);
}

function parseOptions(variants) {
    let options = [];

    variants.forEach(function (variant) {
        variant.options.forEach(function (vo) {
            let co;
            options.forEach(function (option) {
                if (option.name === vo.name) {
                    co = option;
                }
            });
            if (typeof co === 'undefined') {
                co = {
                    id: vo.id,
                    name: vo.name,
                    order: vo.order,
                    values: []
                };
                options.push(co);
            }
            let cov;
            co.values.forEach(function (value) {
                if (value.name === vo.value.name) {
                    cov = value;
                }
            });
            if (typeof cov === 'undefined') {
                cov = {
                    id: vo.value.id,
                    name: vo.value.name,
                    order: vo.value.order
                };
                co.values.push(cov);
            }
            co.values.sort((cov1, cov2) => cov1.order - cov2.order);
        });
    });

    options.sort((co1, co2) => co1.order - co2.order);

    return options;
}

function findCombinations(option, variants, selected) {
    const ids = [];
    variants.forEach(function (variant) {
        const vids = [];
        variant.options.forEach(function (vo) {
            vids.push(vo.value.id);
        });
        let isCombination = false;
        vids.forEach(function (id) {
            if (selected.indexOf(id) !== -1) {
                isCombination = true;
            }
        });
        if (isCombination || vids.length === 1) {
            vids.forEach(function (id) {
                //if (option.values.indexOf(id) === -1) {
                ids.push(id);
                //}
            });
        }
    });
    return ids;
}

function getVariant(variants, selected) {
    let variant = null;

    variants.forEach(function (v) {
        const vids = [];

        v.options.forEach(function (vo) {
            vids.push(vo.value.id);
        });

        if (vids.every((val) => selected.includes(val))) {
            variant = v;
        }
    });
    return variant;
}

function getSelected() {
    const selected = [];
    $('body #options').find('.selected').each(function () {
        const id = parseInt($(this).data('id'));
        selected.push(id)
    });
    return selected;
}

function getVariantBySize(variants, s) {
    let variant = null;
    variants.forEach(function (v) {
        v.options.forEach(function (vo) {
            if (vo.value.name == s) {
                variant = v;
            }
        });
    });
    return variant;
}

function getQueryString() {
    var key = false, res = {}, itm = null;
    // get the query string without the ?
    var qs = location.search.substring(1);
    // check for the key as an argument
    if (arguments.length > 0 && arguments[0].length > 1)
        key = arguments[0];
    // make a regex pattern to grab key/value
    var pattern = /([^&=]+)=([^&]*)/g;
    // loop the items in the query string, either
    // find a match to the argument, or build an object
    // with key/value pairs
    while (itm = pattern.exec(qs)) {
        if (key !== false && decodeURIComponent(itm[1]) === key)
            return decodeURIComponent(itm[2]);
        else if (key === false)
            res[decodeURIComponent(itm[1])] = decodeURIComponent(itm[2]);
    }

    return key === false ? res : null;
}

function getDefaultSelected(variants) {
    var qs = getQueryString('s');
    const s = qs.s;

    let variant = null;
    if (null !== s) {
        variant = getVariantBySize(variants, s);
    }

    if (null === variant && variants.length) {
        variant = variants[0];
    }

    const selected = [];
    if (null !== variant) {
        variant.options.forEach(function (vo) {
            selected.push(vo.value.id);
        });
    }
    return selected;
}

function updateOptions(variants, selected = []) {
    if (selected.length === 0) {
        selected = getDefaultSelected(variants);
    }

    const options = parseOptions(variants);
    const variant = getVariant(variants, selected);

    if (null !== variant) {
        if (variant.can_add_to_cart) {
            $('.btn_buy').removeClass('hidden');
            $('#variant').val(variant.id);

            const $price = $('<div/>', {class: 'price', id: 'selectedVariantPrice'});
            if (null !== variant.discount_price) {
                const $old = $('<span/>', {class: 'old'}).text(variant.price);
                $price.append($old).append(variant.discount_price);
            } else {
                $price.text(variant.price);
            }
            $('#selectedVariantPrice').replaceWith($price);

            $('#selectedVariantDeliveryTime').html(variant.delivery_time);
            $('.delivery_time_visible').text(variant.delivery_time).fadeIn(500);
            $('#remindMeBox').removeClass('show');
        } else {
            $('#variant').val('null');
            $('.btn_buy').addClass('hidden');
            $('#selectedVariantDeliveryTime').html(variant.delivery_time);
            $('.delivery_time_visible').text(variant.delivery_time).fadeIn(500);
            $('#remindMeBox').addClass('show');
            $('#remindMeBox input[name=variant_id]').val(variant.id);

            if(variant.options[0] !== undefined){
                $('#remindMeBox input[name=size]').val(variant.options[0].value.name);
            }
        }
    } else {
        $('#variant').val('null');
        $('.btn_buy').addClass('hidden');
        $('.delivery_time_visible').text('Tijdelijk niet te bestellen.').fadeIn(500);
    }
    $('body #options').empty();
    options.forEach(function (option) {
        const combinations = findCombinations(option, variants, selected);

        const $option = $('<div/>').append($('<h4/>', {class: 'value_option'}).text('Kies een ' + option.name.toLowerCase()));
        const $values = $('<div/>', {class: 'values'}).appendTo($option);

        option.values.forEach(function (value) {

            if (selected.indexOf(value.id) !== -1) {
                value.selected = true;
            } else if (combinations.indexOf(value.id) === -1) {
                value.disabled = true;
            }
            const $value = $('<span/>', {
                class: 'value',
                'data-type': option.name,
                'data-id': value.id,
                'data-order': value.order
            })
                .append(value.name)
                .append($('<span/>', {class: 'delivery_time hidden-xs'}).text('Op aanvraag'))
                .appendTo($values);

            if (value.selected) {
                $value.addClass('selected');
            }

            if (value.disabled) {
                $value.addClass('disabled');
            }
        });

        $values.each(function (i, el) {
            $(el).children().on('click', function (e) {
                e.preventDefault();

                const $value = $(this);

                if (!$value.hasClass('disabled')) {
                    $(el).children().removeClass('selected');
                    $value.addClass('selected');
                }

                const selected = getSelected();

                updateOptions(variants, selected);
            });

            $(el).children().on('mouseenter', function (e) {
                const $value = $(this);

                const id = $value.data('id');
                const selected = getSelected();

                // Currently selected value for option
                const svfo = parseInt($value.parent().find('.selected').data('id'));
                selected[selected.indexOf(svfo)] = id;

                const variant = getVariant(variants, selected);

                $value.find('.delivery_time').text(variant.delivery_time);
                $value.addClass('hover');
            });

            $(el).children().on('mouseleave', function (e) {
                const $value = $(this);

                $value.removeClass('hover');
            });

            if ($(el).find('.selected').length === 0) {
                $(el).children().first().addClass('selected');
            }
        });
        $('body #options').append($option);
    });
}
